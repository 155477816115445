
import './App.css';

function App() {
  return (
    <div >
     <h1>Hello from react</h1>
    </div>
  );
}

export default App;
